import Cookies from "js-cookie";
import fetch from 'cross-fetch';
import {
    ApolloClient,
    HttpLink,
    ApolloLink,
    InMemoryCache,
    concat,
} from "@apollo/client";

const httpLink = new HttpLink({
    uri: `http://localhost:8080/query`, // @todo process.env.YOLK_API_URI,
    fetch
    // credentials: "include", //  backend is a different domain.
    // credentials: 'same-origin', // @todo if on same domain 'same-origin'
});

// authorize each api call
const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            authorization: Cookies.get("_ylk_tk") || null // Cookies.get(env.REACT_APP_COOKIE_TOKE_COOKIE_NAME) || null //  //  
        },
    });

    return forward(operation);
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, httpLink),
});

export { client }