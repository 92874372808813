import React from "react";
import { Link } from "gatsby";

import { Head } from "../../app/head"

import "./modal.sass";

const ModalLayout = (props) => {

    const width = props.width ? `w-${props.width}`: ''

    return (
        <React.Fragment>
            <Head htmlTitle={props.htmltitle ? `Yolk Analytics | ${props.head.htmlTitle}` : `Yolk Analytics`} />
            <div className="app">
                <div className="singleFocus">
                    <div className="logoBox">
                        <img src="/images/yolk-icon.png" className="logo" alt="Yolk Data" />
                    </div>
                    <div className={`content ${width}`}>
                        {props.skipTo ? (
                            <div className="skip">
                                <Link to={props.skipTo}>Do this later ></Link>
                            </div>
                        ) : null}
                        {props.pageTitle && <div className="title" id="title">{props.pageTitle}</div>}
                        {props.pageTagline && <div className="tagline" id="tagline">{props.pageTagline}</div>}
                        {props.introBody && <div className="contentBody">{props.introBody}</div>}
                        {props.form && props.form}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export { ModalLayout };